@import './_variables.scss';
@import './tooltip.scss';

html, body {
  font-family: "Montserrat-Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $dark;
}

* {
  margin:0;
  padding:0;
}

*:focus {
  outline: none;
}

body {
  height: 100%;
  background-color: $bg-grey;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

h1 {
  font-size: 20px;
}

h2 {
  font-size: 14px;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

.text-primary {
  color:$primary;
}

.text-orange {
  color:$orange;
}

.text-error {
  color:$error;
}

.text-success {
  color:$success;
}

.bold {
  font-family: "Montserrat-SemiBold", sans-serif;
}

.uppercase {
  text-transform: uppercase;
}

.regular {
  font-family: "Montserrat-Regular", sans-serif;
}


input, button {
  font-family: "Montserrat-Regular", sans-serif;
}

.ml-20 {
  margin-left: 20px;
}

.rotate180 {
  transform: rotate(180deg);
}

.tel {
  color: $primary;
  text-decoration: underline;
}

.hide-mobile {
  display: block;
  &.text {
    display: inline-block;
    @media screen and (max-width: $sm-screen) {
      display: none;
    }
  }
  @media screen and (max-width: $sm-screen) {
    display: none;
  }
}

.hide-desktop {
  display: none;
  @media screen and (max-width: $sm-screen) {
    display: block;
  }
}

.app-container {
  min-height: 100vh;
  width: 100%;
  position: relative;
  overflow-x: hidden;
}

.link {
  cursor: pointer;
}

.page-container {
  width: calc(100% - 190px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 190px;
  animation-name: slide-left;
  animation-duration: 0.5s;
  @media screen and (max-width: $sm-screen) {
    justify-content: flex-start;
    width: 100vw;
    margin-left: 0px;
    min-height: 100%;
  }
}

.slide-left {
  animation-name: slide-left;
  animation-duration: 0.5s;
}

@keyframes slide-left {
  from {
    transform: translate( 70px,0);
    opacity: 0;
  }
  to {
    transform: translate(0,0);
    opacity: 1;
  }
}

.slide-bottom {
  animation-name: slide-bottom;
  animation-fill-mode: forwards;
  animation-duration: 0.5s;
}

@keyframes slide-bottom {
  from {
    transform: translate( 0, 70px);
    opacity: 0;
  }
  to {
    transform: translate(0,0);
    opacity: 1;
  }
}

.page-header {
  width: 90%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  margin: 30px auto;
  &.space-between {
    justify-content: space-between;
  }
  @media screen and (max-width: $sm-screen) {
    margin: 0px;
    width: 100%;
    padding: 15px 20px;
    position: fixed;
    background-color: $bg-grey;
    z-index: 4;
    box-shadow: $shadow;
  }
  h1 {
    @media screen and (max-width: $xs-screen) {
      max-width: 70%;
      font-size: 16px;
    }
  }
}

.page-content {
  position: relative;
  width: 90%;
  max-width: 1200px;
  margin: auto;
  margin-bottom: 80px;
  @media screen and (max-width: $sm-screen) {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}

.exhibition {
  margin-bottom: 20px;
  .toggle {
    align-items: center;
    margin-bottom: 10px;
    padding: 3px 12px 0px 1px;
    border-radius: 12px;
    border: 1px solid #E8E8E8;
    .switch {
      padding-right: 6px;
      transform: scale(0.8);
    }
    p {
      margin-bottom: 3px;
    }
  }
  @media screen and (min-width: 1047px) {
    margin-bottom: 0px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -60px;
    right: 0px;
    .toggle {
      margin-left: 10px;
    }
  }
}

.btn-delete {
  @include btn;
  color: $error;
  &:hover {
    text-decoration: underline;
  }
}

.row {
  display: flex;
  &.flex-end {
    justify-content: flex-end;
    align-items: flex-start;
  }
  &.space-between {
    justify-content: space-between;
    align-items: flex-start;
  }
  &.col-2 {
    justify-content: space-between;
  }
  .search-icon {
    position: absolute;
    width: 20px;
    pointer-events: none ;
    right: 0px;
    transform: translate(-10px , 15px);
  }
  @media screen and (max-width: $md-screen) {
    flex-direction: column;
    justify-content: flex-start;
    &.export {
      flex-direction: row;
      justify-content: flex-start;
      @media screen and (max-width: $sm-screen) {
        flex-direction: column;

      }
    }
  }
  .col-1 {
    width: 100%;
  }
  .col-2 {
    width: 49%;
    margin-bottom: 20px;
    @media screen and (max-width: $md-screen) {
      width: 100%;
    }
  }
  .col-70 {
    width: 70%;
    margin-bottom: 20px;
    @media screen and (max-width: $md-screen) {
      width: 100%;
    }
  }
  .col-3 {
    width: calc(33.3% - 20px);
    margin-bottom: 1%;
    margin-right: 20px;
    @media screen and (max-width: $md-screen) {
      width: 100%;
      max-width: 100%;
    }
  }
}

.search-container {
  .select {
    margin: 5px 20px;
    @media screen and (max-width: $md-screen) {
      margin: 22px 0px;
    }
  }
}

.input {
  width:100%;
  max-width: 100%;
  display: flex;
  font-size: 14px;
  flex-direction: column;
  position: relative;
  &.offer-number {
    width: 49px;
    input {
      text-align: center;
    }
  }
  label {
    margin-left: 0px;
  }
  input {
    margin: 5px 0px;
    border: solid 1px #B2B2B2;
    height: 37px;
    font-size: 14px;
    border-radius: 12px;
    padding: 0px 10px;
    &.error {
      border: 1px solid $error;
    }
  }
}

textarea {
  border: 1px solid rgba($color: #0A1760, $alpha: 0.28);
  border-radius: 13px;
  font-family: "Montserrat-Regular", sans-serif;
  padding: 0px 10px;
  max-width: 100%;
  min-width: calc(100% - 10px);
}

.input {
  @include input;
}

.icon {
  width: 36px;
  min-width: 36px;
  height: 36px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  background-color: $primary;
  &.transparent {
    background-color: transparent;
  }
  &.product {
    background-color: transparent;
    img {
      width: 25px;
      height: 25px;
    }
  }
  img {
    width: 20px;
    height: 20px;
  }
}

.title {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  h2 {
    border-bottom: 1px solid $primary;
  }
  .btn-edit {
    margin-left: 20px;
  }
}

.card {
  width: auto;
  margin: 10px 0px 20px 0px;
  padding: 20px 0px 20px 20px;;
  border-radius: 6px;
  font-size: 14px;
  background-color: $white;
  box-shadow: $shadow;
  h1 {
    margin-bottom: 20px;
  }
  .btn-container {
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      margin:10px;
    }
    @media screen and (max-width: $sm-screen) {
      margin-right: 0px;
      flex-direction: column;
    }
  }
  &.notes {
    padding: 20px;
    min-height: 100px;
  }
  &.showroom-opt {
    padding: 20px;
    min-height: 250px;
  }
  &.info {
    min-height: 370px;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
  }
  .products {
    display: flex;
    margin-bottom: 10px;
    p {
      background-color: $primary;
    }
  }
  .product-img {
    max-width: calc(100% - 20px);
    img {
      width: 100%;
    }
  }
  @media screen and (max-width: $sm-screen) {
    padding: 20px;
  }
}

.search-error {
  margin-top: 20px;  
}


.badge-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  h1 {
    text-transform: uppercase;
  }
  img {
    width: 150px;
  }
  .logo-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: $primary;
    img {
      width: 50px;
      border-radius: 28px;
    }
  }
  @media screen and (max-width: $sm-screen) {
    min-height: 70vh;
  }
}

.btn-qr-container {
  position: fixed;
  bottom: 0px;
  width: 85%;
  display: flex;
  justify-content: center;
  background: linear-gradient( transparent ,$white);
  @media screen and (max-width: $sm-screen) {
    align-items: center;
    width: 100%;
  }
}

.stands-dashboard {
  display: flex;
  background-color: $primary;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 6px;
  @media screen and (max-width: $sm-screen) {
    flex-direction: column;
  }
  .stands-products {
    display: flex;
    flex-wrap: wrap;
    width: 70%;
    @media screen and (max-width: $sm-screen) {
      width: 100%;
    }
    .count {
      width: calc((100% / 5) - 40px);
      padding: 20px;
      color: $white;
      @media screen and (max-width: $sm-screen) {
        width: calc(50% - 40px);
      }
      label {
        font-weight: bold;
        font-size: 14px;
      }
      h2 {
        font-size: 25px;
      }
    }
  }
  .stands-global {
    width: 30%;
    background-color: $primary-dark;
    border-radius: 0px 6px 6px 0px;
    @media screen and (max-width: $sm-screen) {
      width: 100%;
      background-color: $primary-dark;
      border-radius: 0px 0px 6px 6px;
    }
    .count {
      text-align: right;
      padding: 20px;
      color: $white;
      @media screen and (max-width: $sm-screen) {
        text-align: left;
      }
      label {
        font-weight: bold;
        font-size: 14px;
      }
      h2 {
        font-size: 25px;
      }
    }
  }
}

.btn-scan {
  width: 70px;
  height: 70px;
  border-radius: 35px;
  background-color: $orange;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 30px; 
  }
}

